import React from 'react';
import './App.css';

import mixpanel from 'mixpanel-browser';
mixpanel.init("8f09bb52d73ab498ca75ea55f6e15d1e");

const NUX_EXERCISE_ID = "en_tf_0002_nux";

const ANSWER_SUBMISSION = {
  NONE: "none",
  CORRECT_ANSWER: "correct_answer",
  INCORRECT_ANSWER: "incorrect_answer",
  NOT_SELECTED: "not_selected",
  RESUBMIT_ANSWER: "resubmit_answer"
}

const FETCH_SOURCE = {
  NEXT_QUESTION: "next_question",
  SKIP_QUESTION: "skip_question",
  COMPONENT_MOUNT: "component_mount"
}

const QUESTION_TYPE = {
  TRUE_FALSE: "true_false",
  MATCHING: "matching",
  MCQ: "mcq"
}

const QUESTION_MATCHING_SUBTYPE = {
  GENERAL: "general",
  WORD_MEANING: "word_meaning",
  COMPLETE_SENTENCE: "complete_sentence"
}

const QUESTION_MCQ_SUBTYPE = {
  FILL_BLANK: "fill_blank"
}

const ANSWER_COUNT_KEYS = {
  N_QUESTIONS: "n_questions",
  N_CORRECT_ANSWERS: "n_correct_answers",
  N_INCORRECT_ANSWERS: "n_incorrect_answers",
  N_INCOMPLETE_ANSWERS: "n_incomplete_answers",
}

const LOCAL_KEYS = {
  UID: "uid",
  SUBMITTED_QUESTIONS: "submitted_questions",
  RECEIVED_QUESTIONS: "received_questions",
  TOTAL_CORRECT: "total_correct",
  TOTAL_ATTEMPT: "total_attempt"
}

class Exercise extends React.Component {

  resetSelectedAnswers() {
    // TODO: If there are ever more than 12 questions, increase this.
    return [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1];
  }

  resetMatchingQuestions() {
    // TODO: If there are ever more than 12 questions, increase this.
    return [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1];
  }

  resetSubmissionState() {
    // TODO: If there are ever more than 12 questions, increase this.
    return [
      ANSWER_SUBMISSION.NONE, ANSWER_SUBMISSION.NONE, ANSWER_SUBMISSION.NONE,
      ANSWER_SUBMISSION.NONE, ANSWER_SUBMISSION.NONE, ANSWER_SUBMISSION.NONE,
      ANSWER_SUBMISSION.NONE, ANSWER_SUBMISSION.NONE, ANSWER_SUBMISSION.NONE,
      ANSWER_SUBMISSION.NONE, ANSWER_SUBMISSION.NONE, ANSWER_SUBMISSION.NONE
    ];
  }

  resetAnswerCounts() {
    return {
      n_questions: 0,
      n_correct_answers: 0,
      n_incorrect_answers: 0,
      n_incomplete_answers: 0
    };
  }

  getNUXContent() {
    return "Welcome to hamroQ.com! hamroQ.com helps you practice SEE English questions and get immediate answers. Remember to share it with your friends. Let’s start!";
  }

  getNUXExercise() {
    return {
      "exercise": {
        "id": NUX_EXERCISE_ID,
        "type": "true_false",
        "subtype": "true_false",
        "para": "Narayan Gopal is by far the most prominent and popular singer in Nepali music history. Narayan Gopal Gurwacharya was born in a Newar family in Kilagal Tole, Kathmandu, on October 4, 1939 to father Gopal Gurwacharya and mother Ram Devi Gurwacharya. He had five brothers and six sisters. He completed his School Leaving Certificate (SLC) in 2016 B.S. and obtained Bachelor of Arts Degree in Humanities from Tri-Chandra college.",
        "questions": [
          {"question": "Narayan Gopal is one of the famous artists of Nepal.", "options": ["True", "False"], "correct_option": 0},
          {"question": "He was born in Newar family in Patan.", "options": ["True", "False"], "correct_option": 1},
          {"question": "Narayan Gopal studied Science.", "options": ["True", "False"], "correct_option": 1}
        ]
      }
    };
  }

  initLocalStorageData(uid) {
    localStorage.setItem(LOCAL_KEYS.UID, uid);
    localStorage.setItem(LOCAL_KEYS.SUBMITTED_QUESTIONS, "");
    localStorage.setItem(LOCAL_KEYS.RECEIVED_QUESTIONS, "");
    localStorage.setItem(LOCAL_KEYS.TOTAL_CORRECT, 0);
    localStorage.setItem(LOCAL_KEYS.TOTAL_ATTEMPT, 0);
  }

  getOrCreateUIDAndStats() {
    let is_new_user = false;
    let uid = localStorage.getItem(LOCAL_KEYS.UID);
    if (uid === null) {
      var time_now = Date.now();
      var random_id = Math.round(Math.random() * 1000000);
      uid = time_now + "_" + random_id;

      this.initLocalStorageData(uid);

      // Just in case, sanity testing.
      uid = localStorage.getItem(LOCAL_KEYS.UID);
      is_new_user = true;
      mixpanel.track('new_user', {
        uid: uid,
        client_time_ms: Date.now(),
      });
    }
    let total_correct_str = localStorage.getItem(LOCAL_KEYS.TOTAL_CORRECT);
    let total_attempt_str = localStorage.getItem(LOCAL_KEYS.TOTAL_ATTEMPT);
    if (total_correct_str === null) {
      localStorage.setItem(LOCAL_KEYS.TOTAL_CORRECT, 0);
      localStorage.setItem(LOCAL_KEYS.TOTAL_ATTEMPT, 0);
      total_correct_str = localStorage.getItem(LOCAL_KEYS.TOTAL_CORRECT);
      total_attempt_str = localStorage.getItem(LOCAL_KEYS.TOTAL_ATTEMPT);
    }

    let total_correct = parseInt(total_correct_str);
    let total_attempt = parseInt(total_attempt_str);

    let is_in_test = this.isInTest(uid);
    // console.log(is_in_test);

    // console.log("UID: " + uid);
    return [uid, is_new_user, total_correct, total_attempt, is_in_test];
  }

  isInTest(uid) {
    if (uid.length === 0) {
      return false;
    }
    let last_digit = uid.slice(-1);
    let is_even =
      last_digit === '0' ||
      last_digit === '2' ||
      last_digit === '4' ||
      last_digit === '6' ||
      last_digit === '8';
    is_even = true;
    return is_even;
  }

  constructor(props) {
    super(props);

    let uid_and_stats = this.getOrCreateUIDAndStats();
    let uid = uid_and_stats[0];
    let is_new_user = uid_and_stats[1];
    let total_correct = uid_and_stats[2];
    let total_attempt = uid_and_stats[3];
    let is_in_test = uid_and_stats[4];
    // console.log("DEBUG: total_correct " + total_correct);
    // console.log("DEBUG: total_attempt " + total_attempt);

    let exercise = null;
    let is_fetching_exercise = true;
    if (is_new_user) {
      exercise = this.getNUXExercise().exercise;
      is_fetching_exercise = false;
    }

    this.state = {
      uid: uid,
      is_ranklist_loaded: false,
      show_ranklist: false,
      ranklist_data: {},
      ranking: -1,
      is_in_test: is_in_test,
      is_fetching_exercise: is_fetching_exercise,
      total_correct: total_correct,
      total_attempt: total_attempt,
      exercise: exercise,
      exercise_para_preview: null,
      prev_submission_answer_selected_value: this.resetSelectedAnswers(),
      answer_selected_value: this.resetSelectedAnswers(),
      matching_questions: this.resetMatchingQuestions(),
      hint_shown_or_hidden_state: false,  // false: hint hidden, true: hint shown.
      answer_submission_state: this.resetSubmissionState(),
      show_submit_info: false,
      submit_explanation: null,
      submit_correct: false,
      submit_next: false,
      answer_counts: this.resetAnswerCounts()
    };

    this.toggleHint = this.toggleHint.bind(this);
    this.handleAnswerSelected = this.handleAnswerSelected.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.initializeStateWithReceivedData = this.initializeStateWithReceivedData.bind(this);

    this.resetCachedEvents();

    mixpanel.track('begin', {
      uid: this.state.uid,
      client_time_ms: Date.now(),
      is_in_test: is_in_test,
    });
  }

  resetCachedEvents() {
    this.cachedEvents = [];
  }

  addCachedEvents(event) {
    this.cachedEvents.push(event);
  }

  getCachedEvents() {
    return this.cachedEvents;
  }

  initializeStateWithReceivedData(data, fetch_start_time_ms, source) {
    let fetch_end_time_ms = Date.now();
    // console.log('initializeStateWithReceivedData');
    // console.log(data);
    let exercise_para_preview = data.exercise.para.slice(0, 100);
    // console.log('setting');
    this.setState({
      is_fetching_exercise: false,
      exercise: data.exercise,
      exercise_para_preview: exercise_para_preview,
      prev_submission_answer_selected_value: this.resetSelectedAnswers(),
      answer_selected_value: this.resetSelectedAnswers(),
      matching_questions: this.resetMatchingQuestions(),
      hint_shown_or_hidden_state: false,
      answer_submission_state: this.resetSubmissionState(),
      show_submit_info: false,
      submit_correct: false,
      answer_counts: this.resetAnswerCounts()
    });
    var received_questions = localStorage.getItem(LOCAL_KEYS.RECEIVED_QUESTIONS);
    received_questions += "," + data.exercise.id;
    localStorage.setItem(LOCAL_KEYS.RECEIVED_QUESTIONS, received_questions);

    mixpanel.track('fetch_next_question_end', {
      uid: this.state.uid,
      client_time_ms: Date.now(),
      source: source,
      exercise_id: data.exercise.id,
      exercise: {
        id: data.exercise.id,
        questions: data.exercise.questions,
        type: data.exercise.type,
        para_preview: exercise_para_preview,
        subtype: data.exercise.subtype,
      },
      fetch_total_time_ms: fetch_end_time_ms - fetch_start_time_ms,
      fetch_time_ms: {
        start: fetch_start_time_ms,
        end: fetch_end_time_ms,
        total: fetch_end_time_ms - fetch_start_time_ms,
      },
    });
  }

  constructQuestionQuery(data_fetch_url) {
    var new_url = data_fetch_url + "/question";
    var uid = localStorage.getItem(LOCAL_KEYS.UID);
    if (uid !== null) {
      var received_questions = localStorage.getItem(LOCAL_KEYS.RECEIVED_QUESTIONS);
      var submitted_questions = localStorage.getItem(LOCAL_KEYS.SUBMITTED_QUESTIONS);
      new_url += "?uid=" + uid;
      new_url += "&received_questions=" + received_questions;
      new_url += "&submitted_questions=" + submitted_questions;
    }
    return new_url;
  }

  componentDidMount() {
    if (this.state.is_fetching_exercise) {
      this.fetchNextQuestion(FETCH_SOURCE.COMPONENT_MOUNT);
    }
    // console.log('here');
    this.fetchRanklist();
  }

  computeRanking(total_correct, total_attempt, ranklist_data) {
    let ranking = this.state.ranking;
    // console.log('line0')
    // console.log(ranklist_data);
    if (!ranklist_data.hasOwnProperty('counts')) {
      return ranking;
    }
    // console.log('line1');

    const arr_counts = ranklist_data.counts;
    for (let i = arr_counts.length - 1; i >= 0; i--) {
      const item = arr_counts[i];
      if (item.correct > total_correct) {
        break;
      }
      if (
        item.correct === total_correct &&
        item.attempt > total_attempt
      ) {
        break;
      }
      // console.log('line2' + i);
      ranking = (item.total + 1);
    }

    const arr_top_n = ranklist_data.top_n;
    for (let i = arr_top_n.length - 1; i >= 0; i--) {
      const item = arr_top_n[i];
      if (item.correct > total_correct) {
        break;
      }
      if (
        item.correct === total_correct &&
        item.attempt < total_attempt
      ) {
        break;
      }
      // console.log('line2' + i);
      ranking = i + 1;
    }

    // console.log('line3');
    return ranking;
  }

  initializeStateWithRanklist(data, fetch_start_time_ms) {
    let fetch_end_time_ms = Date.now();
    // console.log("ranking: " + ranking);
    this.setState({
      is_ranklist_loaded: true,
      ranklist_data: data,
      ranking: this.computeRanking(this.state.total_correct, this.state.total_attempt, data),
    });

    mixpanel.track('fetch_ranklist_end', {
      uid: this.state.uid,
      client_time_ms: Date.now(),
      fetch_total_time_ms: fetch_end_time_ms - fetch_start_time_ms,
      fetch_time_ms: {
        start: fetch_start_time_ms,
        end: fetch_end_time_ms,
        total: fetch_end_time_ms - fetch_start_time_ms,
      },
    });
  }

  fetchRanklist() {
    let data_fetch_url = this.getDataFetchUrlBase();
    data_fetch_url += "/ranklist";

    let fetch_start_time_ms = Date.now();
    fetch(data_fetch_url)
      .then(res => res.json())
      .then((data) => {
        // console.log("dataConsumer. We loaded data just now");
        // console.log(data);
        this.initializeStateWithRanklist(data, fetch_start_time_ms);
      })
      .catch(console.log);
  }

  fetchNextQuestionCaller(e, source) {
    // console.log(e);
    // console.log('fetchNextQuestionCaller');
    this.fetchNextQuestion(source);
  }

  getDataFetchUrlBase() {
    let hostname = window.location.hostname;
    let data_fetch_url = window.location.protocol + '//' + hostname;
    if (hostname !== 'www.hamroq.com' && hostname !== 'hamroq.com') {
      data_fetch_url += ":5000";
    }
    data_fetch_url += "/api";

    return data_fetch_url;
  }

  fetchNextQuestion(source) {
    if (this.state.is_fetching_exercise) {
      if (source !== 'component_mount') {
        return;
      }
    }
    this.setState(state => ({
      is_fetching_exercise: true,
    }));

    let data_fetch_url = this.getDataFetchUrlBase();
    data_fetch_url = this.constructQuestionQuery(data_fetch_url);
    // console.log('data_fetch_url: ' + data_fetch_url);

    let fetch_start_time_ms = Date.now();
    mixpanel.track('fetch_next_question_start', {
      uid: this.state.uid,
      client_time_ms: fetch_start_time_ms,
      source: source,
      cached_events: this.getCachedEvents(),
      data_fetch_url: data_fetch_url,
    });
    this.resetCachedEvents();

    fetch(data_fetch_url)
    .then(res => res.json())
    .then((data) => {
      // console.log("dataConsumer. We loaded data just now");
      // console.log(data);
      this.initializeStateWithReceivedData(data, fetch_start_time_ms, source);
    })
    .catch(console.log);
    // console.log("componentDidMount. We loaded data just now");
  }

  renderPara() {
    if (this.state.exercise.type === QUESTION_TYPE.MCQ) {
      return null;
    }

    const para =
      <div>
        <div className="para-text">
          <label>{this.state.exercise.para}</label>
        </div>
      </div>;

    return para;
  }

  renderRanklistScreen() {
    let str_correct_answers = "You have " + this.state.total_correct +
      " correct answers.";
    let str_ranking = "Your all-Nepal-ranking is " + this.state.ranking + " of " +
      (this.state.ranklist_data.total_count + 1) + " people.";
    return (
      <div>
        <p>{str_correct_answers}</p>
        <p><b>{str_ranking}</b></p>
        <p>Solve more questions to increase your ranking!</p>
        <p><i>All-Nepal-ranking is updated multiple times a day. Remember to come back every day to see the updated ranking.</i></p>
        {this.renderRanklistTable()}
        <p><i>Only the top scores are shown above. Rest of the scores are not shown.</i></p>
      </div>
    );
  }

  renderRanklistTable() {
    const top_n = this.state.ranklist_data.top_n;
    const table_rows = top_n.map((row, row_id) => {
      let correct = row['correct'];
      let attempt = row['attempt'];
      let city = row['city'];
      // console.log('question_id is ');
      // console.log(question_id);
      // console.log('question is {question}');
      // console.log(question);
      // var answer_feedback_component = this.renderAnswerFeedback(question_id);
      // var question_label = null;
      // if (state_questions.length !== 1) {
      //   question_label = String.fromCharCode(97 + question_id) + ". ";  // 97 is 'a'.
      // }
      let current_user_row = null;
      if (row_id + 1 === this.state.ranking) {
        current_user_row = (
          <tr key={"row-current"}>
            <td className="ranklist-cell ranklist-highlight-current-user-cell">{row_id + 1}</td>
            <td className="ranklist-cell ranklist-highlight-current-user-cell">{this.state.total_correct}</td>
            <td className="ranklist-cell ranklist-highlight-current-user-cell">{this.state.total_attempt}</td>
            <td className="ranklist-cell ranklist-highlight-current-user-cell">Your Ranking</td>
          </tr>
        );
      }
      // Update rank issue for rows below the current user (who is inserted).
      let offset = 0;
      if (row_id + 1 >= this.state.ranking) {
        offset = 1;
      }
      let data = (
        <tr key={"row"+row_id}>
          <td className="ranklist-cell">{row_id + 1 + offset}</td>
          <td className="ranklist-cell ranklist-highlight-correct-cell">{correct}</td>
          <td className="ranklist-cell">{attempt}</td>
          <td className="ranklist-cell">{city}</td>
        </tr>
      );
      return [current_user_row,data];
    });
    return (
      <table className="ranklist-table">
        <thead>
          <tr key="header">
            <th className="ranklist-cell" scope="col">Rank</th>
            <th className="ranklist-cell ranklist-highlight-correct-cell" scope="col">Correct</th>
            <th className="ranklist-cell" scope="col">Attempted</th>
            <th className="ranklist-cell" scope="col">City</th>
          </tr>
        </thead>
        <tbody>
          {table_rows}
        </tbody>
      </table>
    );
  }


  renderQuestions() {
    const type = this.state.exercise.type;
    if (type === QUESTION_TYPE.MATCHING) {
      return this.renderQuestionsMatching();
    } else if (type === QUESTION_TYPE.TRUE_FALSE) {
      return this.renderQuestionsMCQTrueFalse();
    } else if (type === QUESTION_TYPE.MCQ) {
      return this.renderQuestionsMCQTrueFalse();
    }
  }

  handleMatchingSelected(e, question_id) {
    const target = e.target;
    // console.log(this.state.hint_shown_or_hidden_state);
    // console.log("Printing e");
    // console.log(e);
    // console.log(target);
    // console.log(question_id);
    // console.log(option_id);
    let target_value = parseInt(target.value);
    // console.log(target_value);

    let selected_vals = this.state.answer_selected_value;
    let submission_vals = this.state.answer_submission_state;
    let matching_questions = this.resetMatchingQuestions().slice();

    selected_vals[question_id] = target_value;
    if (submission_vals[question_id] !== ANSWER_SUBMISSION.NONE) {
      submission_vals[question_id] = ANSWER_SUBMISSION.RESUBMIT_ANSWER;
    }
    let n_questions = this.state.exercise.questions.length;
    for (let i = 0; i < n_questions; i++) {
      let option_id = selected_vals[i];
      if (option_id !== -1) {
        matching_questions[option_id] = i;
      }
    }

    this.setState(state => ({
      // answer_selected_value: selected_vals
      answer_selected_value: selected_vals,
      answer_submission_state: submission_vals,
      matching_questions: matching_questions
    }));
    // console.log(selected_vals);
    // console.log(submission_vals);
    let answer_state = 'incorrect';
    if (selected_vals[question_id] === this.state.exercise.questions[question_id].correct_option) {
      answer_state= 'correct';
    }

    this.addCachedEvents({
      event: 'answer_selected',
      uid: this.state.uid,
      client_time_ms: Date.now(),
      exercise_id: this.state.exercise.id,
      exercise_type: this.state.exercise.type,
      answer_selected_answer_state: answer_state,
      answer_selected: {
        question_id: question_id,
        option_id: target_value,
        answer_state: answer_state,
        selected_val: selected_vals[question_id],
        submission_val: submission_vals[question_id],
      },
    });
  }

  renderMatchingSelect(question_id, question_content) {
    const state_questions = this.state.exercise.questions;
    const options = state_questions.map((question, option_id) => {
      var question_label = this.getQuestionOptionLabel(option_id);  // 97 is 'a'.
      let content = question_label + ". " + question.options;
      return (
        <option value={option_id} key={"option:" + question_id + ":" + option_id}>
          {content}
        </option>
      );
    });
    let select_background = this.state.answer_selected_value[question_id] === -1?"matching-not-selected":"matching-selected";
    return (
      <span className={select_background}>
        <select className="matching-select" value={this.state.answer_selected_value[question_id]} onChange={e => this.handleMatchingSelected(e, question_id)}>
          <option value="-1" disabled="disabled" key={"option:" + question_id + ":-1"}>
            Click and choose the correct answer.
          </option>
          <option value="-2" disabled="disabled" key={"option:" + question_id + ":-2"}>
            {question_content}
          </option>
          {options}
        </select>
      </span>
    );
  }

  renderAnswerFeedback(question_id) {
    var answer_feedback = null;
    var answer_feedback_css = null;
    const answer_submission_state = this.state.answer_submission_state[question_id];
    if (answer_submission_state === ANSWER_SUBMISSION.INCORRECT_ANSWER) {
      answer_feedback = "Incorrect answer, try again.";
      answer_feedback_css = "answer-feedback-incorrect";
    } else if (answer_submission_state === ANSWER_SUBMISSION.NOT_SELECTED) {
      answer_feedback = "Choose an answer.";
      answer_feedback_css = "answer-feedback-incorrect";
    } else if (answer_submission_state === ANSWER_SUBMISSION.CORRECT_ANSWER) {
      answer_feedback = "Correct answer.";
      answer_feedback_css = "answer-feedback-correct";
    } else if (answer_submission_state === ANSWER_SUBMISSION.RESUBMIT_ANSWER) {
      answer_feedback = "Submit answer to check.";
      answer_feedback_css = "answer-feedback-resubmit";
    }
    var answer_feedback_component = null;
    if (answer_feedback !== null) {
      answer_feedback_component = (
        <div className="answer-feedback">
          <div className={answer_feedback_css}>
            {answer_feedback}
          </div>
        </div>
      );
    }
    return answer_feedback_component;
  }

  renderQuestionsMatching() {
    const state_questions = this.state.exercise.questions;
    const questions = state_questions.map((question, question_id) => {
      // console.log('question_id is ');
      // console.log(question_id);
      // console.log('question is {question}');
      // console.log(question);
      var answer_feedback_component = this.renderAnswerFeedback(question_id);
      var question_label = String.fromCharCode(97 + question_id);  // 97 is 'a'.
      var question_content = question_label + ". " + question.question;
      return (
        <div className="questions-text" key={"question" + question_id}>
          <hr/>
          {answer_feedback_component}
          <div className="question-text">
            {question_content}
            {" "}
            {this.renderMatchingSelect(question_id, question_content)}
          </div>
        </div>
      );
    });

    const matching_questions = this.state.matching_questions;
    // let option_selected = this.getOptionSelectedList();
    const options = state_questions.map((question, option_id) => {
      // console.log('option_id is ');
      // console.log(option_id);
      // console.log('question is {question}');
      // console.log(question);
      var question_label = this.getQuestionOptionLabel(option_id);  // 97 is 'a'.
      // use option_selected[option_id] to denote option was selected.
      let content = question_label + ". " + question.options;
      let css = "questions-text";
      if (matching_questions[option_id] >= 0) {
        css = "questions-text answers-text-selected";
      }
      return (
        <div className={css} key={"option" + option_id}>
          <hr/>
          <div className="question-text">
            {content}
          </div>
        </div>
      );
    });

    let left_column_css = "left-column-55";
    let right_column_css = "right-column-45";
    // Narrower column for word meaning.
    if (this.state.exercise.subtype === QUESTION_MATCHING_SUBTYPE.WORD_MEANING) {
      left_column_css = "left-column-45";
      right_column_css = "right-column-55";
    }

    return (
      <div className="columns-wrapper">
        <div className="columns">
          <div className={left_column_css}>
            <b>Column 'A'</b>
            {questions}
          </div>
          <div className={right_column_css}>
            <b>Column 'B'</b>
            {options}
          </div>
        </div>
      </div>
    );
  }

  getQuestionOptionLabel(id) {
    return ["i", "ii", "iii", "iv", "v", "vi", "vii", "viii", "ix", "x", "xi", "xii"][id];
  }

  renderQuestionsMCQTrueFalse() {
    const state_questions = this.state.exercise.questions;
    const questions = state_questions.map((question, question_id) => {
      // console.log('question_id is ');
      // console.log(question_id);
      // console.log('question is {question}');
      // console.log(question);
      var answer_feedback_component = this.renderAnswerFeedback(question_id);
      var question_label = null;
      if (state_questions.length !== 1) {
        question_label = String.fromCharCode(97 + question_id) + ". ";  // 97 is 'a'.
      }
      return (
        <div className="questions-text" key={"question" + question_id}>
          <hr/>
          {answer_feedback_component}
          <div className="question-text">
            {question_label}
            {question.question}
          </div>
          {this.renderOptionsMCQTrueFalse(question.options, question_id)}
        </div>
      );
    });
    return questions;
  }

  renderOptionsMCQTrueFalse(options, question_id) {
    const rendered_answers = options.map((option, option_id) => {
      // console.log('key is ');
      // console.log(key);
      // console.log('answer is {answer}');
      // console.log(answer);
      // console.log(question_id + ":" + this.state.answer_selected_value[question_id] + ":" + option_id);
      return (
        <div className="answer-text" key={"question" + question_id + ":" + option_id}>
          <label className="radio-clickable">
            <input
              type="radio"
              name={"pick-answers-" + question_id}
              value={option_id}
              checked={this.state.answer_selected_value[question_id] === option_id}
              onChange={() => {}}
              onClick={e => this.handleAnswerSelected(e, question_id, option_id)}/>
            {option}
          </label>
        </div>
      );
    });
    return rendered_answers;
  }

  getSubmissionExplanationStr(answer_counts) {
    // console.log(answer_counts);
    let n_questions = answer_counts[ANSWER_COUNT_KEYS.N_QUESTIONS];
    let n_correct_answers = answer_counts[ANSWER_COUNT_KEYS.N_CORRECT_ANSWERS];
    let n_incorrect_answers = answer_counts[ANSWER_COUNT_KEYS.N_INCORRECT_ANSWERS];
    let n_incomplete_answers = answer_counts[ANSWER_COUNT_KEYS.N_INCOMPLETE_ANSWERS];
    if (n_questions <= 0) {
      return null;
    }
    if (n_correct_answers + n_incorrect_answers + n_incomplete_answers === 0) {
      // TODO: Log error.
      return null;
    }
    if (n_questions === n_correct_answers) {
      if (n_questions === 1) {
        return "Congratulations! You answered the question correctly.";
      }
      return "Congratulations! You answered all questions correctly.";
    }

    var strs = [];
    if (n_correct_answers > 0) {
      strs.push(n_correct_answers + " correct");
    }
    if (n_incorrect_answers > 0) {
      strs.push(n_incorrect_answers + " incorrect");
    }
    if (n_incomplete_answers > 0) {
      strs.push(n_incomplete_answers + " incomplete");
    }
    var r_str = strs.join(', ');
    r_str += " of " + n_questions + " questions, try again.";
    // console.log(r_str);
    return r_str;
  }

  renderDisabledSubmitComponent() {
    return this.renderSubmitComponentHelper(false);
  }

  renderSubmitComponent() {
    return this.renderSubmitComponentHelper(true);
  }

  renderSubmitComponentHelper(is_enabled) {
    var submit_explanation_1 = null;

    if (this.state.show_submit_info) {
      if (this.submit_explanation !== null) {
        submit_explanation_1 = this.state.submit_explanation;
      } else {
        // TODO: Log error.
      }
    }

    var submit_button = null;
    if (this.state.submit_correct) {
      submit_button = (
        <div className="wide-button">
          <button
            className={"submit-button-activated"}
            type="button"
            onClick={e => this.fetchNextQuestionCaller(e, FETCH_SOURCE.NEXT_QUESTION)}>
            NEXT QUESTION
          </button>
        </div>
      );
    } else {
      submit_button = (
        <div className="wide-button">
          <button
            className={"submit-button-activated"}
            type="button"
            onClick={this.handleSubmit}>
            SUBMIT ANSWERS
          </button>
        </div>
      );
    }

    // <img className="image-score" src={img_retry} />
    let submit_explanation = null;
    if (submit_explanation_1) {
      submit_explanation =
        <div className="submit-hint">
          {submit_explanation_1}
        </div>;
    }

    const submit_button_wrapper =
      <div className="submit-button-wrapper">
        <div className="columns-wrapper">
          <div className="columns">
            <div className="left-column-40-bottom">
              <div className="wide-button">
                <button
                  className={"skip-button"}
                  type="button"
                  onClick={e => this.fetchNextQuestionCaller(e, FETCH_SOURCE.SKIP_QUESTION)}>
                  SKIP QUESTION
                </button>
              </div>
            </div>
            <div className="right-column-60-bottom">
              {submit_button}
            </div>
          </div>
        </div>
      </div>;

    const submit_component =
      <div className="submit-component">
        {submit_explanation}
        {submit_button_wrapper}
      </div>;

    return submit_component;
  }

  toggleHint() {
    // console.log(this.state.hint_shown_or_hidden_state);
    let hint_shown_or_hidden_state = !this.state.hint_shown_or_hidden_state;
    this.setState(state => ({
      hint_shown_or_hidden_state: hint_shown_or_hidden_state,
    }));

    this.addCachedEvents({
      event: 'toggle_about',
      uid: this.state.uid,
      client_time_ms: Date.now(),
      hint_shown_or_hidden_state: hint_shown_or_hidden_state,
    });
  }

  handleAnswerSelected(e, question_id, option_id) {
    // const target = e.target;
    // console.log(this.state.hint_shown_or_hidden_state);
    // console.log("Printing e");
    // console.log(e);
    // console.log(target);
    // console.log(question_id);
    // console.log(option_id);
    if (option_id !== this.state.answer_selected_value[question_id]) {
      var selected_vals = this.state.answer_selected_value;
      var submission_vals = this.state.answer_submission_state;
      selected_vals[question_id] = option_id;
      if (submission_vals[question_id] !== ANSWER_SUBMISSION.NONE) {
        submission_vals[question_id] = ANSWER_SUBMISSION.RESUBMIT_ANSWER;
      }
      this.setState(state => ({
        // answer_selected_value: selected_vals
        answer_selected_value: selected_vals,
        answer_submission_state: submission_vals
      }));
      // console.log(selected_vals);
      // console.log(submission_vals);
      let answer_state = 'incorrect';
      if (selected_vals[question_id] === this.state.exercise.questions[question_id].correct_option) {
        answer_state= 'correct';
      }

      this.addCachedEvents({
        event: 'answer_selected',
        uid: this.state.uid,
        client_time_ms: Date.now(),
        exercise_id: this.state.exercise.id,
        exercise_type: this.state.exercise.type,
        answer_selected_answer_state: answer_state,
        answer_selected: {
          question_id: question_id,
          option_id: option_id,
          answer_state: answer_state,
          selected_val: selected_vals[question_id],
          submission_val: submission_vals[question_id],
        },
      });
    }
  }

  // TODO: Are there any unusual race conditions when updating these values?
  // Unlikely but think through it time permitting.
  updateAndGetScores(total_correct_delta, total_attempt_delta) {
    if (total_correct_delta > 0) {
      let total = parseInt(localStorage.getItem(LOCAL_KEYS.TOTAL_CORRECT));
      // console.log('previous, total_correct: ' + total);
      total += total_correct_delta;
      localStorage.setItem(LOCAL_KEYS.TOTAL_CORRECT, total);
    }
    if (total_attempt_delta > 0) {
      let total = parseInt(localStorage.getItem(LOCAL_KEYS.TOTAL_ATTEMPT));
      // console.log('previous, total_attempt: ' + total);
      total += total_attempt_delta;
      localStorage.setItem(LOCAL_KEYS.TOTAL_ATTEMPT, total);
    }
    let total_correct = localStorage.getItem(LOCAL_KEYS.TOTAL_CORRECT);
    let total_attempt = localStorage.getItem(LOCAL_KEYS.TOTAL_ATTEMPT);
    // console.log('updated, total_correct: ' + total_correct);
    // console.log('updated, total_attempt: ' + total_attempt);
    return [total_correct, total_attempt];
  }

  handleSubmit() {
    if (this.state.is_fetching_exercise) {
      return;
    }
    // console.log(this.state.hint_shown_or_hidden_state);
    // console.log(this.state.answer_selected_value);
    // console.log("before_answer_submission_state: " + this.state.answer_submission_state);

    var n_questions = this.state.exercise.questions.length;
    var t_state = this.resetSubmissionState();
    var t_counts = this.resetAnswerCounts();
    t_counts[ANSWER_COUNT_KEYS.N_QUESTIONS] = n_questions;
    for (let i = 0; i < n_questions; i++) {
      const selected_value = this.state.answer_selected_value[i];
      // console.log("selected_value: " + selected_value);
      // console.log("this.state.exercise.questions.correct_option: " + this.state.exercise.questions[i].correct_option);
      // console.log("this.state.exercise.questions: " + this.state.exercise.questions);
      if (selected_value === -1) {
        t_state[i] = ANSWER_SUBMISSION.NOT_SELECTED;
        t_counts[ANSWER_COUNT_KEYS.N_INCOMPLETE_ANSWERS] += 1;
      } else if (selected_value === this.state.exercise.questions[i].correct_option) {
        t_state[i] = ANSWER_SUBMISSION.CORRECT_ANSWER;
        t_counts[ANSWER_COUNT_KEYS.N_CORRECT_ANSWERS] += 1;
      } else {
        t_state[i] = ANSWER_SUBMISSION.INCORRECT_ANSWER;
        t_counts[ANSWER_COUNT_KEYS.N_INCORRECT_ANSWERS] += 1;
      }
    }

    // Find delta
    let total_correct_delta = 0;
    let total_attempt_delta = 0;
    let total_incorrect_delta = 0;
    let exercise_incorrect = 0;
    let exercise_correct = 0;
    let exercise_attempt = 0;
    for (let i = 0; i < n_questions; i++) {
      const prev_selected_value = this.state.prev_submission_answer_selected_value[i];
      const selected_value = this.state.answer_selected_value[i];

      // exercise attempt.
      if (t_state[i] !== ANSWER_SUBMISSION.NOT_SELECTED) {
        exercise_attempt += 1;
      }
      if (t_state[i] === ANSWER_SUBMISSION.CORRECT_ANSWER) {
        exercise_correct += 1;
      }
      if (t_state[i] === ANSWER_SUBMISSION.INCORRECT_ANSWER) {
        exercise_incorrect += 1;
      }

      // No change for this answer between previous submission and
      // current one, so do nothing.
      if (prev_selected_value === selected_value) continue;
      // Count as attempt only if some answer was selected.
      if (t_state[i] !== ANSWER_SUBMISSION.NOT_SELECTED) {
        total_attempt_delta += 1;
      }
      if (t_state[i] === ANSWER_SUBMISSION.CORRECT_ANSWER) {
        total_correct_delta += 1;
      }
      if (t_state[i] === ANSWER_SUBMISSION.INCORRECT_ANSWER) {
        total_incorrect_delta += 1;
      }
    }
    let scores = this.updateAndGetScores(total_correct_delta, total_attempt_delta);
    let total_correct = scores[0];
    let total_attempt = scores[1];
    let prev_submission_answer_selected_value = this.state.answer_selected_value.slice();

    // this.state.answer_submission_state = t_state;
    this.setState(state => ({
      total_correct: total_correct,
      total_attempt: total_attempt,
      prev_submission_answer_selected_value: prev_submission_answer_selected_value,
      answer_submission_state: t_state,
      show_submit_info: true,
      answer_counts: t_counts,
      submit_explanation: this.getSubmissionExplanationStr(t_counts),
      // submit_skip: (n_questions !== t_counts[ANSWER_COUNT_KEYS.N_CORRECT_ANSWERS]),
      submit_correct: (n_questions === t_counts[ANSWER_COUNT_KEYS.N_CORRECT_ANSWERS]),
      ranking: this.computeRanking(total_correct, total_attempt, this.state.ranklist_data),
    }));
    // console.log(t_counts);

    mixpanel.track('handle_submit', {
      uid: this.state.uid,
      client_time_ms: Date.now(),
      exercise_id: this.state.exercise.id,
      cached_events: this.getCachedEvents(),
      is_in_test: this.state.is_in_test,
      exercise: {
        id: this.state.exercise.id,
        type: this.state.exercise.type,
        questions: this.state.exercise.questions,
        para_preview: this.state.exercise_para_preview,
        subtype: this.state.exercise.subtype,
      },
      submission_state: {
        prev_submission_answer_selected_value: prev_submission_answer_selected_value,
        answer_submission_state: t_state,
        show_submit_info: true,
        answer_counts: t_counts,
        // submit_skip: (n_questions !== t_counts[ANSWER_COUNT_KEYS.N_CORRECT_ANSWERS]),
        submit_correct: (n_questions === t_counts[ANSWER_COUNT_KEYS.N_CORRECT_ANSWERS]),
      },
      exercise_incorrect: exercise_incorrect,
      exercise_correct: exercise_correct,
      exercise_attempt: exercise_attempt,
      score: {
        exercise: {'incorrect': exercise_incorrect, 'correct': exercise_correct, 'attempt': exercise_attempt},
        delta: {'incorrect': total_incorrect_delta, 'correct': total_correct_delta, 'attempt': total_attempt_delta},
        total: {'correct': total_correct, 'attempt': total_attempt},
      },
    });
    this.resetCachedEvents();
  }

  getHintComponent() {
    let hint_description = null;
    if (this.state.hint_shown_or_hidden_state) {
      hint_description =
        <div className="hint-text">
        <p>
        hamroQ.com is a mobile website that allows students in Nepal to practice
        Secondary Education Exam (SEE) exams. The students can read hundreds
        of sample questions from past SEE exams on the mobile site and input their
        answers. The website then tells the students if the answers are
        correct and gives a score to the student. Thus, the student can
        practice a large number of questions at the convenience of their
        phone, in an engaging and fun format, and improve their score in the
        SEE exams.
        </p>
        <p>
        hamroQ.com is a collaboration between the University of Houston, Teach for
        Nepal, and other organizations and individuals who are committed to
        improving the state of education in Nepal through digital, Internet,
        and mobile technologies. We work with technology and education
        experts, and students to create solutions that tries to address the
        gaps in access to rich, relevant, and engaging content to all students
        regardless of where they are and who they are.
        </p>
        <p>
        Leadership: Prof. Omprakash Gnawali, Dr. Topraj Gurung, Mr. Shisir
        Khanal.
        </p>
        <p>
        Please contact us at: info@hamroq.com
        </p>
      </div>;
    }

    const hint_component =
      <div className="hint-component">
        <div className="hint-padding"></div>
        <div>
          <button className="hint-button" type="button" onClick={this.toggleHint}>
            <u>About us</u>
          </button>
        </div>
        {hint_description}
      </div>;
    return hint_component;
  }

  getNQuestionContent(n, is_matching = false) {
    if (n === 1) {
      return "";
    } else {
      return " There are " + n + (is_matching ? " matchings." : " questions.");
    }
  }

  renderQuestionsInstructions() {
    var content = "";
    // console.log(this.state);
    // console.log(this.state.exercise);
    // console.log(this.state.exercise.questions);
    var n_questions = this.state.exercise.questions.length;
    // console.log("n_questions: " + n_questions);
    switch(this.state.exercise.type) {
      case QUESTION_TYPE.TRUE_FALSE:
        content = "Choose 'True' for true and 'False' for false statements. ";
        content += this.getNQuestionContent(n_questions);
        break;
      case QUESTION_TYPE.MCQ:
        if (this.state.exercise.subtype === QUESTION_MCQ_SUBTYPE.FILL_BLANK) {
          content = "Choose the best answer to fill in the blank. ";
        }
        content += this.getNQuestionContent(n_questions);
        break;
      case QUESTION_TYPE.MATCHING:
        if (this.state.exercise.subtype === QUESTION_MATCHING_SUBTYPE.WORD_MEANING) {
          content = "Match the following words in column 'A' " +
            "with their meanings in column 'B'.";
        } else if (this.state.exercise.subtype === QUESTION_MATCHING_SUBTYPE.COMPLETE_SENTENCE) {
          content = "Match the first halves of the sentences in column 'A' " +
            "with the correct second halves in column 'B'.";
        } else if (this.state.exercise.subtype === QUESTION_MATCHING_SUBTYPE.GENERAL) {
          content = "Match the words/phrases in column 'A' " +
            "with the appropriate information in column 'B'.";
        }
        content += " Click on the red boxes in column 'A' to choose the matching in column 'B'.";
        content += this.getNQuestionContent(n_questions, true);
        break;
      default:
        console.log("Unrecognized question type: " + this.state.exercise.type);
        break;
    }

    var hr_component = null;
    if (this.state.exercise.type !== QUESTION_TYPE.MCQ) {
      hr_component = (<hr/>);
    }
    return (
      <div>
        {hr_component}
        <div className="para-text-instruction">
          <b>{content}</b>
        </div>
      </div>
    );
  }

  renderParaInstructions() {
    if (this.state.exercise.type === QUESTION_TYPE.MCQ) {
      return null;
    }

    var content = "Read the following text and do the tasks.";
    return (
      <div>
        <div className="para-text-instruction">
          <label><b>{content}</b></label>
        </div>
      </div>
    );
  }

  renderLoadingScreen() {
    return (
      <div>
        <div className="para-text">
          <label>Loading question, please wait</label>
        </div>
      </div>
    );
  }

  isExerciseLoaded() {
    if (typeof this.state.exercise !== 'undefined') {
      // console.log("isExerciseLoaded");
      // console.log(this.state.exercise);
      // console.log((this.state.exercise == null));
      return this.state.exercise != null;
    }
    return false;
  }

  toggleRank() {
    let new_show_ranklist_state = !this.state.show_ranklist;
    this.setState({
      show_ranklist: new_show_ranklist_state,
    });
    mixpanel.track('toggle_rank', {
      uid: this.state.uid,
      client_time_ms: Date.now(),
      exercise_id: this.state.exercise.id,
      is_in_test: this.state.is_in_test,
      new_show_ranklist_state: new_show_ranklist_state,
    });
  }

  renderRankingComponent() {
    if (!this.state.is_in_test) {
      return null;
    }
    if (!this.state.is_ranklist_loaded) {
      return null;
    }

    let str_ranking = "Your Nepal Rank: " + this.state.ranking + " of " +
      (this.state.ranklist_data.total_count + 1);
    return (
      <div className="ranking-button-wrapper">
        <div className="columns-wrapper">
          <div className="columns">
            <div className="left-column-50-bottom">
              <div className="ranking-left-component">
                {str_ranking}
              </div>
            </div>
            <div className="right-column-50-bottom">
              <div className="wide-button">
                <button
                  className={this.state.show_ranklist ? "rank-button-red" : "rank-button-green"}
                  type="button"
                  onClick={e => this.toggleRank(e, 'top')}>
                  {
                    this.state.show_ranklist ?
                    "Go back to Questions":
                    "Click to see Nepal Toppers"
                  }
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderTopComponent() {
    // <img className="image-score" src={img_score} />
    const component =
      <div className="top-component">
        <div className="top-background top-background-color">
          <div className="score-component">
            hamroQ.com{', '}
            {this.state.total_correct} CORRECT{', '}
            {this.state.total_attempt} ATTEMPTED
          </div>
        </div>
        {this.renderRankingComponent()}
      </div>;

    return component;
  }

  renderNuxWelcomeMessage() {
    if (this.state.exercise.id !== NUX_EXERCISE_ID) {
      return null;
    }

    return (
      <div className="para-text">
        <label><i>{this.getNUXContent()}</i></label>
      </div>
    );
  }

  getTopPadding() {
    if (this.state.is_in_test) {
      return "page-top-behind-top-padding-long";
    }
    return "page-top-behind-top-padding-short";
  }

  render() {
    // var is_exercise_loaded = this.isExerciseLoaded();
    // console.log("DEBUG: is_exercise_loaded " + is_exercise_loaded);
    // console.log('is_exercise_loaded' + is_exercise_loaded);
    // if (!is_exercise_loaded) {
    //   return this.renderLoadingScreen();
    // }
    // console.log('error');
    // console.log(this.state.is_fetching_exercise);

    if (this.state.is_in_test && this.state.show_ranklist) {
      const top_component = this.renderTopComponent();
      const body = this.renderRanklistScreen();
      // const submit_component = this.renderDisabledSubmitComponent();
      return (
        <div className="master-component">
          <div className="top-component">
            {top_component}
          </div>
          <div className={this.getTopPadding()}>
          </div>
          {body}
        </div>
      );
    }
    if (this.state.is_fetching_exercise) {
      const top_component = this.renderTopComponent();
      const body = this.renderLoadingScreen();
      const submit_component = this.renderDisabledSubmitComponent();
      return (
        <div className="master-component">
          <div className="top-component">
            {top_component}
          </div>
          <div className={this.getTopPadding()}>
          </div>
          {body}
          {submit_component}
        </div>
      );
    } else {
      const top_component = this.renderTopComponent();
      const welcome_message = this.renderNuxWelcomeMessage();
      const para_instructions = this.renderParaInstructions();
      const para = this.renderPara();
      const questions_instructions = this.renderQuestionsInstructions();
      const questions = this.renderQuestions();
      const submit_component = this.renderSubmitComponent();
      const hint_component = this.getHintComponent();

      return (
        <div className="master-component">
          <div className="top-component">
            {top_component}
          </div>
          <div className={this.getTopPadding()}>
          </div>
          {welcome_message}
          {para_instructions}
          {para}
          {questions_instructions}
          {questions}
          {submit_component}
          <div className="page-bottom-behind-submit-button-padding-1"></div>
          {hint_component}
          <div className="page-bottom-behind-submit-button-padding-2"></div>
        </div>
      );
    }
  }
}

function App() {
  return (
    <div className="App">
      <Exercise/>
    </div>
  );
}

export default App;
